import React from "react";
import KeyboardAltIcon from "@mui/icons-material/KeyboardAlt";
import { Box } from "@mui/system";
import { Tooltip } from "@mui/material";

const Logo = ({ isFocusedMode }) => {

  return (
    <div className="header" style={{visibility: isFocusedMode ? 'hidden' : 'visible' }}>
      <Box display='block' flexDirection='row' className="resposive-header-links">
      <Tooltip
              title={
                <span style={{ whiteSpace: "pre-line", fontSize: "12px" }}>
                  About us
                </span>
              }
              placement="top-start"
            >
              <a href="about-us.html" style={{marginRight: "1rem"}}>About</a>
            </Tooltip>

            <Tooltip
              title={
                <span style={{ whiteSpace: "pre-line", fontSize: "12px" }}>
                  Contact us
                </span>
              }
              placement="top-start"
            >
              <a href="contact-us.html" style={{marginRight: "1rem"}}>Contact</a>
            </Tooltip>
            {/* <Tooltip
              title={
                <span style={{ whiteSpace: "pre-line", fontSize: "12px" }}>
                  Articles
                </span>
              }
              placement="top-start"
            >
              <a href="articles.html" style={{marginRight: "1rem"}}>Articles</a>
            </Tooltip> */}
      </Box>
     <div>
     <h1>
        Typing to Speed <KeyboardAltIcon fontSize="large" />
      </h1>
      <span className="sub-header">
        accelerate your typing speed
      </span>
     </div>
     <Box display="block" flexDirection="row" className="resposive-header-links">
            <Tooltip
              title={
                <span style={{ whiteSpace: "pre-line", fontSize: "12px" }}>
                  Terms and Conditions
                </span>
              }
              placement="top-start"
            >
              <a href="terms-and-conditions.html">Terms</a>
            </Tooltip>

            <Tooltip
              title={
                <span style={{ whiteSpace: "pre-line", fontSize: "12px" }}>
                  Privacy Policy
                </span>
              }
              placement="top-start"
            >
              <a href="privacy-policy.html" style={{marginLeft: "1rem"}}>Privacy</a>
            </Tooltip>
            
            <Tooltip
              title={
                <span style={{ whiteSpace: "pre-line", fontSize: "12px" }}>
                  Disclaimer
                </span>
              }
              placement="top-start"
            >
              <a href="disclaimer.html" style={{marginLeft: "1rem"}}>Disclaimer</a>
            </Tooltip>
          </Box>
    </div>
  );
};

export default Logo;
