import React from 'react'
import './pageArticle.css'
import image1 from './typingtospeed.jpg'
import image2 from './typingtospeedqwerty.jpg'
function PageArticle() {
  return (
    <div class="container">
    <h1>What is Typingtospeed.com ?</h1>
    <div className='center'><img src={image1} alt='typing to speed image' className='image-1'/></div>
    <p>The aim of Typingtospeed.com is to help you get better at typing with better speed and accuracy.</p>
    <p>Typingtospeed.com has a good and simple yet soothing user interface which lets you type for hours without getting bored. There are several themes to choose from according to your taste and get familiar with it. I highly recommend you to choose a theme that suits your taste to get a better typing experience.</p>
    <h2>Key Features</h2>
    <ul>
      <li>Good UI</li>
      <li>Word Mode</li>
      <li>Easy/Hard Mode</li>
      <li>Number/Symbol Mode</li>
      <li>Sentence Mode</li>
      <li>Words Card Mode</li>
      <li>Focus Mode</li>
      <li>Free Typing Mode</li>
      <li>QWERTY Keyboard Practice Mode</li>
      <li>In-depth Stats</li>
      <li>Key Stroke Sounds</li>
      <li>Themes</li>
    </ul>
    <h2>Modes</h2>
    <p>The word mode gives you random words to practice with and you can also set if you want hard words or easy words and also the duration to choose from 15 seconds, 30 seconds, or 60 seconds. Similarly, the sentence mode gives you random sentences each time you start typing.</p>
    <p>Additionally, you can have numbers and symbols if you enable the number or symbol mode. The card mode simply gets you a random word and you can also hear the given word by clicking on the speaker button.</p>
    <p>Focus mode helps you to focus more on typing by removing all the header text and other links so that you can just see the typing text in order to get better results.</p>
    <div className='center'><img src={image2} alt='typing to speed keyboard image' className='image-2'/></div>
    <p>QWERTY Keyboard Practice Mode lets you practice QWERTY keys and shows which key you pressed in real-time.</p>
    <p>After completing any of the modes (like words or sentences), you see a results page which shows in-depth details with a visual graph, helping to figure out how many mistakes you made and what your WPM (Words Per Minute) was.</p>
    <p>Key stroke sounds make your typing more engaging. There are three sounds to choose from: Keyboard, Typewriter, and Cherry. If you prefer to be quiet, you can just mute the sound effects.</p>
    <p>You can also choose which type of animation you want when you type. Currently, there are two typing animation options to choose from: 'caret' and 'pulse'. Caret animation puts a "|" symbol where your cursor focus is and the Pulse animation creates an underline to the current word being typed.</p>
    <p>There are some shortcut controls which help you reset the current mode and to restart the mode. To reset the current mode, press TAB + SPACE key together, and to restart the current mode, press TAB + ENTER key together.</p>
  </div>
  )
}

export default PageArticle