import "./onice.css";
import {
  motion,
  useMotionValue,
  useTransform,
  useMotionValueEvent,
} from "framer-motion";
import { useState } from "react";

const Onice = () => {
  const xDrag = useMotionValue(0);
  const [isFolded, setIsFolded] = useState(true);
  const xLeftSection = useTransform(xDrag, [300, 0], ["0%", "100%"]);
  const xRightSection = useTransform(xDrag, [300, 0], ["0%", "-100%"]);
  const centerScale = useTransform(xDrag, [300, 150], [1, 0]);
  const centerBrightness = useTransform(xDrag, [300, 150], [1, 0.2]);

  useMotionValueEvent(xDrag, "change", (currentX) => {
    if (currentX > 260) {
      setIsFolded(false);
    } else {
      setIsFolded(true);
    }
  });

  return (
    <div style={{ overflowX: "clip", rotate: '90deg', boxSizing: "border-box"}}>
      <motion.div
        animate={isFolded ? "folded" : "open"}
        variants={{
          open: { scale: 1 },
          folded: { scale: 0.9 },
        }}
        initial="folded"
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: '20% 2%',
          boxSizing: "border-box"
        }}
      >
        <motion.div
          variants={{ open: { rotate: 0 }, hovering: { rotate: 0 } }}
          whileHover="hovering"
          initial={{ rotate: 3 }}
          style={{
            display: "grid",
            aspectRatio: "16/9",
            width: "100%",
            maxWidth: '1000px',
            padding: "2rem",
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gridArea: "1 / 1",
            }}
          >
            <motion.div
              style={{
                x: xLeftSection,
                skewY: "-1deg",
                transformOrigin: "bottom right",
                borderRightWidth: "1px",
                borderColor: "rgba(255, 255, 255, 0.1)",
                boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
              }}
              className="map-image"
            />
            <motion.div
              style={{
                scaleX: centerScale,
                filter: `brightness(${centerBrightness})`,
              }}
              className="map-image"
            />
            <motion.div
              style={{
                x: xRightSection,
                skewY: "1deg",
                transformOrigin: "bottom left",
                borderLeftWidth: "1px",
                borderColor: "rgba(255, 255, 255, 0.1)",
                boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
              }}
              className="map-image"
            />
          </div>
          <motion.div
            drag="y"
            _dragY={xDrag}
            dragConstraints={{ left: 0, right: 300 }}
            dragTransition={{
              modifyTarget: (target) => {
                return target > 150 ? 300 : 0;
              },
              timeConstant: 45,
            }}
            style={{
              position: "relative",
              zIndex: 10,
              cursor: "grab",
              gridArea: "1 / 1",
            }}
            whileTap={{ cursor: "grabbing" }}
          />
        </motion.div>
        <motion.div
          variants={{
            folded: {
              opacity: 0,
              scale: 0.9,
              y: 30,
            },
            open: {
              opacity: 1,
              scale: 1,
              y: 0,
            },
          }}
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            fontSize: "1.25rem",
            fontWeight: 600,
          }}
        >
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Onice;
